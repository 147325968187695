//import './App.css';
import React, { useState } from "react";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import NecchiLove50 from "./components/Form/NecchiLove50";


const NecchiLove50 = React.lazy(() => import('./components/Form/NecchiLove50'));
const FormVendite = React.lazy(() => import('./components/Form/FormVendite'));
const Richiesta = React.lazy(() => import('./components/Form/RichiestaAssistenza'));

const loading = () => (
  <div className="">Loading...</div>
);

function App() {
  //const { WOO_API_URL } = process.env;
  const [isScrolled, setIsScrolled] = useState(false);

  return (
    <div className="App bg-white p-0">
      <div className="overflow-hidden flex flex-col h-screen">
        <div
          className={
            "container z-50 transition-shadow duration-1000 ease-in-out " +
            (isScrolled && "shadow-md")
          }
        >
          <Header isScrolled={isScrolled}></Header>
        </div>

        <main
          className="flex-1 overflow-y-auto"
          onScroll={(e) => {
            setIsScrolled(e.target.scrollTop > 0 ? true : false);
            //console.log({scrollHeight,scrollLeft,scrollTop, scrollWidth})
          }}
        >
          <div className="container">
          <React.Suspense fallback={loading()}>
            <Router>
              <Switch>
                <Route
                exact
                path="/necchilove50"
                name="NecchiLove"
                render={(props) => <NecchiLove50 {...props} />}
                />
                <Route
                exact
                path="/formvendite"
                name="FormVendite"
                render={(props) => <FormVendite {...props} />}
                />
                <Route
                exact
                path="/richiesta"
                name="FormVendite"
                render={(props) => <Richiesta {...props} />}
                />
              </Switch>
            </Router>
            </React.Suspense>

          </div>
            {/*           <div className="container">
            <div className="flex flex-wrap mx-0 place-content-center ">
              {products.map((product) => (
                <ProductCard product={product} key={product.id} />
              ))}
            </div>
          </div> */}

        </main>

        <footer className="flex flex-col h-8 text-white w-full items-center  place-content-center pt-2">
          <div></div>{" "}
        </footer>
      </div>
    </div>
  );
}

export default App;
