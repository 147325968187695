import React from "react";
import logo from "../assets/img/logo_necchi_200.png";

const Header = ({isScrolled}) => {

  return (
    <div className={"flex justify-between items-center w-full bg-white text-gray-800 h-16 px-0 z-50 " }>
      <div className="pl-2 cursor-pointer">
        <svg
          className="fill-current text-gray-400 hover:text-gray-500 hidden"
          width="24"
          height="18"
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23.76 0H0.24C0.108 0 0 0.0920454 0 0.204545V1.84091C0 1.95341 0.108 2.04545 0.24 2.04545H23.76C23.892 2.04545 24 1.95341 24 1.84091V0.204545C24 0.0920454 23.892 0 23.76 0ZM23.76 15.9545H0.24C0.108 15.9545 0 16.0466 0 16.1591V17.7955C0 17.908 0.108 18 0.24 18H23.76C23.892 18 24 17.908 24 17.7955V16.1591C24 16.0466 23.892 15.9545 23.76 15.9545ZM23.76 7.97727H0.24C0.108 7.97727 0 8.06932 0 8.18182V9.81818C0 9.93068 0.108 10.0227 0.24 10.0227H23.76C23.892 10.0227 24 9.93068 24 9.81818V8.18182C24 8.06932 23.892 7.97727 23.76 7.97727Z" />
        </svg>
      </div>
      <div className="p-2 ml-4">
        <img src={logo} alt="Necchi Italia" />
      </div>
      <div className="flex items-center ">
        <svg
          className="fill-current text-gray-400 hover:text-gray-500 cursor-pointer mr-2 hidden"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20.9369 19.4905L14.1198 12.6734C15.1777 11.3058 15.7499 9.63371 15.7499 7.87497C15.7499 5.76972 14.9283 3.79573 13.4426 2.30736C11.9568 0.818996 9.97758 0 7.87497 0C5.77235 0 3.79311 0.821621 2.30736 2.30736C0.818996 3.79311 0 5.76972 0 7.87497C0 9.97758 0.821621 11.9568 2.30736 13.4426C3.79311 14.9309 5.76972 15.7499 7.87497 15.7499C9.63371 15.7499 11.3032 15.1777 12.6708 14.1224L19.4879 20.9369C19.5079 20.9569 19.5316 20.9728 19.5578 20.9836C19.5839 20.9944 19.6119 21 19.6402 21C19.6684 21 19.6964 20.9944 19.7226 20.9836C19.7487 20.9728 19.7724 20.9569 19.7924 20.9369L20.9369 19.795C20.9569 19.775 20.9728 19.7513 20.9836 19.7252C20.9944 19.6991 21 19.6711 21 19.6428C21 19.6145 20.9944 19.5865 20.9836 19.5604C20.9728 19.5343 20.9569 19.5105 20.9369 19.4905ZM12.0329 12.0329C10.92 13.1433 9.44471 13.7549 7.87497 13.7549C6.30522 13.7549 4.82998 13.1433 3.71698 12.0329C2.60661 10.92 1.99499 9.44471 1.99499 7.87497C1.99499 6.30522 2.60661 4.82735 3.71698 3.71698C4.82998 2.60661 6.30522 1.99499 7.87497 1.99499C9.44471 1.99499 10.9226 2.60399 12.0329 3.71698C13.1433 4.82998 13.7549 6.30522 13.7549 7.87497C13.7549 9.44471 13.1433 10.9226 12.0329 12.0329Z" />
        </svg>

        <svg
          className="fill-current text-gray-400 hover:text-gray-500 cursor-pointer hidden"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M25.459 8.66951C25.3517 8.51442 25.2084 8.38766 25.0414 8.30009C24.8744 8.21252 24.6886 8.16674 24.5 8.16667H8.55518L7.20884 4.93501C7.03231 4.50943 6.73339 4.14586 6.34997 3.89038C5.96655 3.63491 5.51591 3.49904 5.05518 3.50001H2.33334V5.83334H5.05518L10.5898 19.1158C10.6785 19.3284 10.828 19.5099 11.0197 19.6376C11.2113 19.7652 11.4364 19.8334 11.6667 19.8333H21C21.4865 19.8333 21.9217 19.5312 22.0932 19.0773L25.5932 9.74401C25.6593 9.5674 25.6817 9.37738 25.6583 9.19025C25.6349 9.00311 25.5666 8.82443 25.459 8.66951ZM20.1915 17.5H12.4448L9.52818 10.5H22.8165L20.1915 17.5Z" />
          <path d="M12.25 24.5C13.2165 24.5 14 23.7165 14 22.75C14 21.7835 13.2165 21 12.25 21C11.2835 21 10.5 21.7835 10.5 22.75C10.5 23.7165 11.2835 24.5 12.25 24.5Z" />
          <path d="M20.4167 24.5C21.3832 24.5 22.1667 23.7165 22.1667 22.75C22.1667 21.7835 21.3832 21 20.4167 21C19.4502 21 18.6667 21.7835 18.6667 22.75C18.6667 23.7165 19.4502 24.5 20.4167 24.5Z" />
        </svg>
      </div>
    </div>
  );
};

export default Header;
